import * as Sentry from "@sentry/vue";
import { init, captureException } from '@sentry/browser';
import type { Application } from "@hotwired/stimulus"

const isInitSentry: boolean = RAILS_ENV === 'production';

const sentryOption = {
  dsn: SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/game\.hoobby\.net/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  environment: RAILS_ENV,
}

export const initSentryInVue = (app: any) => {
  if (!isInitSentry) { return; }
  Sentry.init({app, ...sentryOption});
}

export const initSentryInStimlus = (application: Application) => {
  if (!isInitSentry) { return; }

  init(sentryOption);
  const defaultErrorHandler = application.handleError.bind(application);
  const sentryErrorHandler = (error: Error, message: string, detail = {}) => {
    defaultErrorHandler(error, message, detail);
    captureException(error, { data: { message, detail } });
  };
  application.handleError = sentryErrorHandler;
}

export const captureResponseToSentry = (event: string, response: any, payload: any) => {
  Sentry.captureMessage(event, {
    extra: {
      payload: JSON.stringify(payload),
      response: {
        status: response.statusCode
      }
    }
  });
}
